function Footer(){
    return(
        <div className="flex flex-col items-center text-white">
            <h1>JustinHolsclaw.com &#64;All Rights Reserved</h1>
            <p>(435) 239-7519</p>
            <br/>
        </div>
    );
}

export default Footer;